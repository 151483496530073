<template>
  <div class="container mx-auto">
    <!-- component -->
    <div class="md:mt-20 sm:mt-0">
      <div class="md:gap-6">
        <div
          class="md:w-1/2 text-left m-auto shadow overflow-hidden sm:rounded-md"
        >
          <div class="grid grid-cols-2 px-4 py-5 bg-white sm:p-6">
            <div class="pb-4 text-lg col-span-2">
              <div>
                <h2 class="text-3xl font-medium text-gray-700 mb-2">
                  {{ t('Accepted your request') }}
                </h2>
              </div>
              <div>
                {{ t('We will send you an email later') }}
              </div>
            </div>
          </div>
          <div class="px-4 py-3 bg-gray-50 text-center sm:px-6">
            <p
              class="cursor-pointer hover:text-blue-500 text-blue-600 font-medium"
              @click="onClick"
            >
              {{ t('Back to Qualitia Domain') }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useTranslation } from '@/locales'
import urlJoin from 'url-join'

export default {
  name: 'ServiceOrderQtDomainSuccess',
  setup(props) {
    const { t } = useTranslation()

    const onClick = () => {
      location.replace(process.env.VUE_APP_QUALITIA_DOMAIN_URL)
    }

    return {
      t,
      onClick,
    }
  },
  methods: {},
}
</script>

<style scoped lang="postcss">
.grid div {
  @apply mx-2;
}

input[type='radio'] {
  @apply mt-1 py-2 px-3 block border border-gray-300 shadow-sm rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm;
}

.required {
  @apply ml-2 bg-red-500 font-medium text-gray-50 text-center inline-block px-2 text-sm;
}
</style>
